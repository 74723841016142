import React, {ChangeEvent} from "react";
import {Input, Navbar, NavLink, ScrollArea} from "@mantine/core";
import {IconLogin, IconLogout} from "@tabler/icons-react";
import {useAuth} from "react-oidc-context";
import {useDebouncedState} from "@mantine/hooks";
import {ContentLanguageTree} from "./ContentLanguageTree";

export const ContentNavBar = () => {
    const auth = useAuth();
    const [searchQuery, setSearchQuery] = useDebouncedState("", 500);

    const handleSignIn = () => {
        void auth.signinRedirect();
    }
    const handleSearchQueryChange = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(e.currentTarget.value);
    }
    return <Navbar width={{base: 300}} p="xs">
        <Navbar.Section>
            <Input defaultValue={searchQuery} onChange={handleSearchQueryChange}/>
        </Navbar.Section>
        {auth.isAuthenticated &&
            <Navbar.Section grow mt="xs" component={ScrollArea} mx={'-xs'} px={'xs'}
                            offsetScrollbars
                            styles={theme => ({
                                viewport: {
                                    '&>*': {
                                        display: 'block !important',
                                    }
                                }
                            })}>
                <ContentLanguageTree filter={searchQuery}/>
            </Navbar.Section>}
        <Navbar.Section>
            {auth.isAuthenticated
                ? <NavLink icon={<IconLogout/>} onClick={auth.removeUser}
                           label={`Log out ${auth.user?.profile.preferred_username ?? ''}`}/>
                : <NavLink icon={<IconLogin/>} onClick={handleSignIn} label="Log in"/>}
        </Navbar.Section>
    </Navbar>
}