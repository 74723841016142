import {
    PublicationEditForm_UpdatePublicationDocument,
    PublicationViewPage_AuthorFragmentDoc,
    PublicationViewPage_PublicationFragment
} from "../../../_gql/graphql";
import {useForm} from "@mantine/form";
import {Box, Button, Divider, Group, Input, NumberInput, Stack, Textarea, TextInput, Title} from "@mantine/core";
import {useFragment} from "../../../_gql";
import React from "react";
import {AuthorSelector} from "../../../components/AuthorSelector";
import {useMutation} from "urql";
import {IMaskInput} from 'react-imask';
import {ITabComponent} from "./PublicationViewPageInternal";

type EditableFields = {
    code: string
    title: string
    description: string
    isbn: string
    pageCount: number | ''
    publicationYear: number | ''
    publisher: string
    purchaseLink: string
    authorId: string
}

const parseIntOrNull = (s: string | number | null | undefined): number | null => {
    if (!s) return null;
    if (typeof s === 'number') return s;
    const i = parseInt(s, 10);
    return Number.isNaN(i) ? null : i;
}

const useEditForm = (model: PublicationViewPage_PublicationFragment) => {
    const author = useFragment(PublicationViewPage_AuthorFragmentDoc, model.author);
    return useForm({
        initialValues: {
            ...model,
            purchaseLink: model.purchaseLink ?? "",
            authorId: author?.id ? ("" + author.id) : "",
        } as EditableFields,
        validate: {
            code: value => !value && 'Code is required',
            title: value => !value && 'Title is required',
            isbn: value => {
                if (!value) return false;
                if (!value.match(/^\d{3}-\d-\d\d-\d{6}-\d$/)) return 'Invalid ISBN format';
                return false;
            },
            purchaseLink: value => {
                if (!value) return false;
                if (!value.match(/^https?:\/\/.+/)) return 'Invalid URL format';
                return false;
            },
            pageCount: value => {
                if (value === '') {
                    return false;
                }
                if (value <= 0) {
                    return 'Page count must be greater than 0';
                }
                if (value > 10000) {
                    return 'Page count must be less than 10000';
                }
                return false;

            },
            publicationYear: value => {
                if (value === '') {
                    return false;
                }
                if (value <= 0) {
                    return 'Page count must be greater than 0';
                }

                if (value > 10000) {
                    return 'Page count must be less than 10000';
                }
                return false;

            }
        }
    });
}

export function PublicationEditForm({model}: ITabComponent) {
    const [, executeUpdate] = useMutation(PublicationEditForm_UpdatePublicationDocument);
    const form = useEditForm(model);

    function handleSubmit(values: EditableFields) {
        const input = {
            ...values,
            publicationId: model.publicationId,
            purchaseLink: values.purchaseLink || null,
            authorId: values.authorId ? parseInt(values.authorId) : null,
            publicationYear: parseIntOrNull(values.publicationYear),
            pageCount: parseIntOrNull(values.pageCount)
        }
        executeUpdate(
            input
        ).then()
    }

    return <Box m={'md'}>
        <Title order={4} mb={'md'}>Edit publication #{model.publicationId}</Title>
        <Divider my={'md'}/>
        <form onSubmit={form.onSubmit(handleSubmit)}>
            <Stack>
                <TextInput withAsterisk
                           label="Code"
                           {...form.getInputProps('code')}/>
                <TextInput withAsterisk
                           label="Title"
                           {...form.getInputProps('title')}/>
                <Textarea label="Description"
                          autosize
                          minRows={3}
                          maxRows={10}
                          {...form.getInputProps('description')}/>
                <Input.Wrapper label="ISBN" {...form.getInputProps('isbn')}>
                    <Input<any>
                        component={IMaskInput}
                        mask={"000-0-00-000000-0"}
                        {...form.getInputProps('isbn')}
                    />
                </Input.Wrapper>
                <NumberInput
                    label="Page count"
                    {...form.getInputProps('pageCount')}/>
                <NumberInput
                    label="Publication year"
                    {...form.getInputProps('publicationYear')}/>
                <TextInput label="Publisher" {...form.getInputProps('publisher')}/>
                <TextInput label="Purchase link" {...form.getInputProps('purchaseLink')}/>
                <AuthorSelector label={'Author'}
                                {...form.getInputProps('authorId')}/>
                <Group position={"apart"}>
                    <Button type="submit">Save</Button>
                    <Button type="reset" color="red" onClick={form.reset}>Reset</Button>
                </Group>
            </Stack>
        </form>
    </Box>
}
