import {useDocumentTitle} from "@mantine/hooks";
import {Dropzone, FileWithPath, MIME_TYPES} from "@mantine/dropzone";
import {IconArchive, IconUpload, IconX} from '@tabler/icons-react';
import dayjs from 'dayjs'
import React, {useEffect, useState} from "react";
import {
    ActionIcon,
    Badge,
    Box,
    Button,
    Group,
    LoadingOverlay, Modal,
    Paper,
    Progress,
    rem,
    SimpleGrid, Table,
    Text,
    Title, Tooltip,
    useMantineTheme
} from "@mantine/core";
import {FileRejection} from "react-dropzone";
import prettyBytes from 'pretty-bytes';
import {API_URI} from "../../_config";
import {useAuth} from "react-oidc-context";
import {useQuery} from "urql";
import {GetQueueLogDocument, GetQueueStatsDocument, QueueLogEntryFragmentDoc} from "../../_gql/graphql";
import {useFragment} from "../../_gql";

import relativeTime from 'dayjs/plugin/relativeTime';
import duration from 'dayjs/plugin/duration';
import {notifications} from "@mantine/notifications";

const allowedMimeTypes = [
    MIME_TYPES.zip,
    "text/html"
]

dayjs.extend(relativeTime)
dayjs.extend(duration)

const FilePreview = ({file, onRemove}: { file: FileWithPath, onRemove: () => void }) => {
    const {name, type, size} = file;
    return <Paper shadow={'xs'} p={'sm'}>
        <Group position={'apart'}>
            <Text size={'md'}>{name}</Text>
            <Text size={'xs'} color={'dimmed'}>{type}</Text>
            <Text size={'xs'} color={'dimmed'} weight={'bold'}>{prettyBytes(size)}</Text>
            <ActionIcon onClick={onRemove}><IconX color={'red'}/></ActionIcon>
        </Group>
    </Paper>
}
const uploadFile = async (file: FileWithPath, accessToken: string): Promise<Response> => {
    const isZip = file.type === MIME_TYPES.zip;
    const uri = API_URI + '/import' + (isZip ? '/zip' : '');
    const data = new FormData();
    data.append('file', file, file.name);
    return await fetch(uri, {
        method: 'POST',
        body: data,
        headers: {
            'authorization': `Bearer ${accessToken}`
        }
    });
}
const ModalLog = () => {
    const [{data}] = useQuery({query: GetQueueLogDocument, requestPolicy: 'network-only'});
    let items = [...useFragment(QueueLogEntryFragmentDoc, data?.queue.log) ?? []]
    items.sort((a, b) => -a.processedAt.localeCompare(b.processedAt));

    return <Table>
        <thead>
        <tr>
            <th>Pub #</th>
            <th>Date</th>
            <th>Duration</th>
            <th>File size</th>
        </tr>
        </thead>
        <tbody>
        {
            items.map(item => {
                const date = dayjs(item.processedAt);
                const duration = dayjs.duration(item.durationMs);
                return <tr key={item.processedAt}>
                    <td>{item.publicationId === 0 ? '—' : item.publicationId}</td>
                    <td><Tooltip label={date.toString()}>
                        <Text>
                            {date.fromNow()}
                        </Text>
                    </Tooltip></td>
                    <td><Tooltip label={duration.asMilliseconds() + 'ms'}><span>{duration.humanize()}</span></Tooltip>
                    </td>
                    <td>{prettyBytes(item.fileSize)}</td>
                    <td><Text color={'red'}>{item.error}</Text></td>
                </tr>
            })
        }</tbody>
    </Table>
}
export const ImportPage = () => {
    const auth = useAuth();
    const [modalOpened, setModalOpened] = useState(false);
    const [files, setFiles] = useState<FileWithPath[]>([]);
    const [uploading, setUploading] = useState<number | null>(null);
    const [{data: queueStats}, refetchQueue] = useQuery({
        query: GetQueueStatsDocument,
        requestPolicy: 'network-only'
    });
    useDocumentTitle("Import page");
    const theme = useMantineTheme();
    const handleDrop = (newFiles: FileWithPath[]) => {
        setFiles([...files, ...newFiles]);
    }
    const handleReject = (files: FileRejection[]) => {
        for (const file of files) {
            notifications.show({
                color: 'red',
                title: `File ${file.file.name} was rejected`,
                message: <ul>
                    {file.errors.map(e => <li>{e.message}</li>)}
                </ul>
            })
        }
    }
    const handleRemove = (idx: number) => {
        files.splice(idx, 1)
        setFiles([...files]);
    }
    const handleUpload = async () => {
        setUploading(0);
        try {
            for (const file of files) {
                await uploadFile(file, auth.user?.access_token ?? '');
                setUploading((uploading as number) + 1);
            }
        } finally {
            setUploading(null);
            setFiles([])
        }
    }
    useEffect(() => {
        const interval = setInterval(async () => {
            await refetchQueue();
        }, 2000);
        return () => clearInterval(interval);
    }, [refetchQueue])

    return <>
        <Group mb={'md'} position="apart">
            <Title order={3}>Imports</Title>
            <Text size={'sm'} color={'dimmed'}>
                Queue size:
                <Badge>{queueStats?.queue.queueSize ?? '-'}</Badge>
                <Button size={'xs'} variant={'subtle'} onClick={() => setModalOpened(true)}>view log</Button>
            </Text>
        </Group>
        <Modal opened={modalOpened} onClose={() => setModalOpened(false)} title="Import log" size={'xl'}>
            {modalOpened && <ModalLog/>}
        </Modal>
        <Box style={{position: 'relative'}}>
            <LoadingOverlay visible={uploading !== null} loader={
                <Progress striped animate value={(uploading as number + 1) * 100 / files.length} size={'lg'}
                          style={{width: '200px'}}/>
            }/>
            <Dropzone onDrop={handleDrop} onReject={handleReject} maxSize={200 * 1024 * 1024}
                      accept={allowedMimeTypes}>
                <Group position="center" spacing="xl" style={{minHeight: rem(220), pointerEvents: 'none'}}>
                    <Dropzone.Accept>
                        <IconUpload
                            size="3.2rem"
                            stroke={1.5}
                            color={theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 4 : 6]}
                        />
                    </Dropzone.Accept>
                    <Dropzone.Reject>
                        <IconX
                            size="3.2rem"
                            stroke={1.5}
                            color={theme.colors.red[theme.colorScheme === 'dark' ? 4 : 6]}
                        />
                    </Dropzone.Reject>
                    <Dropzone.Idle>
                        <IconArchive size="3.2rem" stroke={1.5}/>
                    </Dropzone.Idle>

                    <div>
                        <Text size="xl" inline>
                            Drag html file with WEML document or a zip archive with weml documents here
                        </Text>
                        <Text size="sm" color="dimmed" inline mt={7}>
                            Attach as many files as you like
                        </Text>
                    </div>
                </Group>
            </Dropzone>
            <SimpleGrid cols={1} spacing={'sm'}>
                {files.map((file, idx) => <FilePreview file={file} key={idx} onRemove={() => handleRemove(idx)}/>)}
            </SimpleGrid>
            <Button fullWidth disabled={!files.length} mt={'md'} onClick={handleUpload}>Upload</Button>
        </Box>
    </>
}

