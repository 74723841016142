import {Badge, Text} from "@mantine/core";
import React, {useMemo, useRef} from "react";
import {WHeading} from "./Content/Blocks/WHeading";
import {WTextBlock} from "./Content/Blocks/WTextBlock";
import {WPara} from "./Content/Blocks/WPara";
import {WPage} from "./Content/WPage";
import {WNonEgw} from "./Content/Inlines/WNonEgw";
import {WSent} from "./Content/Inlines/WSent";
import {WFormat} from "./Content/Inlines/WFormat";
import {WNote} from "./Content/Inlines/WNote";

const domParser = new DOMParser();

const transformChildren = (node: Element) => {
    const result = [];
    for (let i = 0; i < node.childNodes.length; i++) {
        const tmp = transformNode(node.childNodes[i]);
        if (tmp) {
            result.push(<React.Fragment key={i}>{tmp}</React.Fragment>);
        }
    }
    return result;
}

// w-entity	Entity	Represents a named span of text that represents some entity
// w-lang	Language block	Represents a span of text in a specific language

const transformElement = (node: Element) => {
    const attr = (name: string) => node.getAttribute(name) ?? undefined;
    const deep = () => transformChildren(node)
    switch (node.nodeName) {
        // BLOCK
        case 'W-PAGE':
            return <WPage number={attr('number')}/>
        case 'W-HEADING':
            return <WHeading level={attr("level")}>{deep()}</WHeading>
        case 'W-PARA':
            return <WPara
                indent={attr("indent")}
                role={attr("role")}
                align={attr("align")}
            >{deep()}</WPara>
        case 'W-TEXT-BLOCK':
            return <WTextBlock>{deep()}</WTextBlock>
        // INLINE
        case "W-NON-EGW":
            return <WNonEgw>{deep()}</WNonEgw>
        case "W-SENT":
            return <WSent>{deep()}</WSent>
        case "W-NOTE":
            const header = node.querySelector('w-note-header');
            const body = node.querySelector('w-note-body');
            const headerChildren = header ? transformChildren(header) : []
            const bodyChildren = body ? transformChildren(body) : []
            return <WNote title={<>{headerChildren}</>}>{bodyChildren}</WNote>
        case "W-FORMAT":
            return <WFormat type={attr('type')}>{deep()}</WFormat>
        case "A":
            return <a href={attr("href")} id={attr("id")}>{deep()}</a>
        case "BR":
            return <br/>
        default:
            console.log("INVALID NODE", node.nodeName, node.attributes);
            return <>{deep()}</>
    }
}
const transformNode = (node: Node): React.ReactNode => {
    if (node.nodeType === Node.TEXT_NODE) {
        return node.textContent ?? '';
    }
    if (node.nodeType === Node.ELEMENT_NODE) {
        return transformElement(node as Element);
    }
    return null;
}

const TransformWeml = (root: Element) => {
    return transformNode(root)
}

export function RenderParagraph({content, refCode}: { content: string, refCode?: string }) {
    const contentRoot = useMemo(() => {
        const dom = domParser.parseFromString(content, "text/html");
        return dom.body.firstElementChild;
    }, [content])

    const childRef = useRef<HTMLDivElement>(null);

    if (!contentRoot) {
        return null;
    }
    const element = TransformWeml(contentRoot);
    // console.log(root);
    return <Text ref={childRef}>
        {element}
        {refCode && <Badge size={'xs'}>{refCode}</Badge>}
    </Text>
}