export function parseParaId(paraId: string): [number, number] | undefined {
    if (!paraId.includes('.')) {
        return;
    }
    const data = paraId.split('.', 2)
        .map(x => Number.parseInt(x, 10));
    if (data.length != 2) {
        return;
    }
    for (const r of data) {
        if (Number.isNaN(r)) {
            return;
        }
    }
    return [data[0], data[1]];

}