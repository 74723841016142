import {useQuery} from "urql";
import {useFragment} from "../../../_gql";
import React from "react";
import {GetPublicationDocument, PublicationViewPage_PublicationFragmentDoc} from "../../../_gql/graphql";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {LoadingCard} from "../../../components/LoadingCard";
import {PublicationViewPageInternal} from "./PublicationViewPageInternal";

function usePublication(id: number) {
    const [{data, fetching}] = useQuery({query: GetPublicationDocument, variables: {id}});
    const publication = useFragment(PublicationViewPage_PublicationFragmentDoc, data?.publication) ?? undefined
    return {publication, fetching}
}

export const PublicationViewPage = () => {
    const {id: idStr} = useParams<{ id: string }>();
    let {hash: tab} = useLocation();
    const navigate = useNavigate();
    const id = Number.parseInt(idStr ?? "", 10);
    const {publication, fetching} = usePublication(id);
    tab = tab?.replace('#', '');
    if (!tab) {
        tab = 'info';
    }
    const setTab = (value: string) => {
        const hash = value === 'info' ? '#' : '#' + value;
        navigate(hash, {replace: true});
    }
    return <LoadingCard
        model={publication}
        loading={fetching}
        title={<><code>{publication?.code ?? ''}</code> {publication?.title ?? ''}</>}>
        {publication && <PublicationViewPageInternal model={publication} tab={tab} onChange={setTab}/>}
    </LoadingCard>
}