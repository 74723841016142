import {useParams} from "react-router-dom";
import {GenericLayout} from "../../layout/GenericLayout";
import {useClient, useQuery} from "urql";
import {
    BookDetailsForReading__ChapterFragmentDoc,
    BookDetailsForReading__ParagraphFragmentDoc,
    BookDetailsForReading__PublicationFragmentDoc,
    GetBookDetailsForReadingDocument,
    LoadContentChunkDocument
} from "../../_gql/graphql";
import {useFragment} from "../../_gql";
import {parseParaId} from "../../shared/paraId";
import {NotFoundPage} from "../../error-pages/NotFoundPage";
import {RenderParagraph} from "../../components/RenderParagraph";
import {ReadPageNavbar} from "./ReadPageNavbar";
import {Box, Title} from "@mantine/core";
import {useEffect, useState} from "react";


const useReading = (publicationId: number, paragraphId: number) => {
    const [{data, fetching}] = useQuery({
        query: GetBookDetailsForReadingDocument,
        variables: {
            publicationId,
            elementId: paragraphId,
            take: 20
        },
        pause: publicationId <= 0
    })
    const publication = useFragment(
        BookDetailsForReading__PublicationFragmentDoc,
        data?.publication
    ) ?? undefined
    const chapters = useFragment(
        BookDetailsForReading__ChapterFragmentDoc,
        data?.publication?.chapters
    ) ?? undefined

    const paragraphs = useFragment(
        BookDetailsForReading__ParagraphFragmentDoc,
        data?.publication?.paragraphs as unknown as any[]
    ) ?? undefined


    return {
        publication,
        chapters,
        paragraphs,
        fetching
    }

}

export function ReadPage() {
    const {id} = useParams<{ id: string }>();
    const parsedPara = parseParaId(id ?? "");
    const {
        publication,
        chapters,
        paragraphs,
    } = useReading(parsedPara ? parsedPara[0] : 0, parsedPara ? parsedPara[1] : 0);
    const client = useClient();

    const [loadedParagraphs, setLoadedParagraphs] = useState(paragraphs);

    useEffect(() => {
        setLoadedParagraphs(paragraphs);
    }, [paragraphs])
    if (!parsedPara) {
        return <NotFoundPage/>
    }


    async function handleLoadPrevious() {
        if (!loadedParagraphs || !loadedParagraphs.length) {
            return;
        }
        const firstElement = parseParaId(loadedParagraphs[0].paraId) ?? [0, 0];
        const item = await client.query(
            LoadContentChunkDocument,
            {
                publicationId: firstElement[0],
                elementId: firstElement[1],
                offset: -10,
                take: 10
            }).toPromise();
        const chunks = item.data?.publication?.paragraphs ?? [];
        setLoadedParagraphs([...chunks as unknown[] as any[], ...loadedParagraphs])

    }

    async function handleLoadNext() {
        if (!loadedParagraphs || !loadedParagraphs.length) {
            return;
        }
        const firstElement = parseParaId(loadedParagraphs[loadedParagraphs.length-1].paraId) ?? [0, 0];
        const item = await client.query(
            LoadContentChunkDocument,
            {
                publicationId: firstElement[0],
                elementId: firstElement[1],
                offset: 1,
                take: 10
            }).toPromise();
        const chunks = item.data?.publication?.paragraphs ?? [];
        setLoadedParagraphs([...loadedParagraphs, ...chunks as unknown[] as any[]])

    }

    return <GenericLayout navbar={chapters && <ReadPageNavbar chapters={chapters}/>}>
        <button onClick={handleLoadPrevious}>prev</button>
        <Title order={3}>{publication?.title}</Title>
        <button onClick={handleLoadNext}>next</button>
        {loadedParagraphs?.map(p => <Box py={'sm'} key={p.paraId}>
            <RenderParagraph
                content={p.content}
                refCode={p.refCodeShort ?? undefined}/>
        </Box>)}
    </GenericLayout>
}