import React, {CSSProperties} from "react";
import {useMantineTheme} from "@mantine/core";

const smallCaps: CSSProperties = {
    fontVariant: "small-caps"
}
const allCaps: CSSProperties = {
    textTransform: "uppercase"
}

export function WFormat({type, children}: {
    type?: string,
    children: React.ReactNode[]
}) {
    switch (type) {
        case "bold" :
            return <b>{children}</b>
        case "italic":
            return <i>{children}</i>
        case "underline" :
            return <u>{children}</u>
        case "superscript" :
            return <sup>{children}</sup>
        case "subscript":
            return <sub>{children}</sub>
        case "small-caps":
            return <span style={smallCaps}>{children}</span>
        case "caps":
            return <span style={allCaps}>{children}</span>
    }
    console.log("Invalid type:", type);
    return <>{children}</>
}