import {ActionIcon, Divider, Stack, Table, Text, TextInput, Title} from "@mantine/core";
import {useDocumentTitle} from "@mantine/hooks";
import React, {useState} from "react";
import {
    FilterPublicationsForExportDocument,
    FilterPublicationsForExportFragmentFragmentDoc,
    PublicationDtoFilterInput
} from "../../_gql/graphql";
import {useQuery} from "urql";
import {useFragment} from "../../_gql";
import {useForm} from "@mantine/form";
import {API_URI} from "../../_config";
import {useAuth} from "react-oidc-context";
import {IconFileExport, IconSearch} from "@tabler/icons-react";

export const ExportPage = () => {
    useDocumentTitle("Exports");
    const auth = useAuth();
    const form = useForm({
        initialValues: {
            filter: ''
        },

    });
    const [filter, setFilter] = useState<PublicationDtoFilterInput | null>(null);

    const [{data: queryData}] = useQuery({
        query: FilterPublicationsForExportDocument,
        variables: {
            where: filter
        }
    })


    const publications = useFragment(FilterPublicationsForExportFragmentFragmentDoc, queryData?.publications?.items) ?? [];

    const handleExport = async (publicationId: number) => {
        const uri = `${API_URI}/export/${publicationId}`
        const request = await fetch(uri, {
            method: 'POST',
            headers: {
                Authorization: "Bearer " + auth.user?.access_token
            }
        });
        const data = await request.text()

        const element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(data));
        element.setAttribute('download', `${publicationId}.html`);
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    }

    const handleSubmit = async () => {
        const input: PublicationDtoFilterInput = {};
        const searchInput = (form.values.filter ?? '').trim();
        if (!searchInput) {
            setFilter(null);
            return;
        }
        input.or = [
            {code: {eq: searchInput}},
            {title: {contains: searchInput}}
        ];
        const value = parseInt(searchInput, 10);
        if (!Number.isNaN(value)) {
            input.or.push({publicationId: {eq: value}});
        }
        setFilter(input);
    }
    return <Stack>
        <Title order={3}>Publication export</Title>
        <Divider my={'sm'}/>

        <form onSubmit={form.onSubmit(handleSubmit)}>
            <TextInput label={'Publication filter'}
                       {...form.getInputProps("filter")}
                       mb={'md'}
                       rightSection={<ActionIcon type={'submit'}><IconSearch/></ActionIcon>}/>
        </form>
        <Table highlightOnHover withBorder>
            <tbody>
            {publications.map(pub => <tr key={pub.publicationId}>
                <td><Text>{pub.publicationId}</Text></td>
                <td><Text>{pub.languageCode}</Text></td>
                <td><Text weight={'bold'}>{pub.code}</Text></td>
                <td><Text size={'sm'} color={'dimmed'} style={{flexGrow: 1}} truncate>{pub.title}</Text></td>
                <td align={'right'}><ActionIcon
                    onClick={() => handleExport(pub.publicationId)} color={'primary'} variant={'light'}><IconFileExport/></ActionIcon></td>
            </tr>)}
            </tbody>
        </Table>


    </Stack>
}