import {useMutation} from "urql";
import {RecalculateGlobalFolderPositionsDocument} from "../../_gql/graphql";

export function RecalculatePage() {
    const [{error, fetching}, recalculate] = useMutation(RecalculateGlobalFolderPositionsDocument);
    const handleRecalculate = async () => {
        await recalculate({});
    }
    return (
        <div>
            <h1>Recalculate</h1>
            <button
                onClick={handleRecalculate}
            >
                Recalculate
            </button>
            {fetching && <div>Loading...</div>}
            {error && <div>Error: {error.message}</div>}
        </div>
    );
}