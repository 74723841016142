import {PublicationType} from "../_gql/graphql";
import {
    IconBook,
    IconBook2,
    IconCalendar,
    IconCrossFilled,
    IconNews, IconPageBreak, IconQuestionMark,
    IconWriting,
    TablerIconsProps
} from "@tabler/icons-react";

export const getPublicationIcon = (type: PublicationType): ((props: TablerIconsProps) => JSX.Element) => {
    switch (type) {
        case PublicationType.Bible:
            return IconCrossFilled;
        case PublicationType.BibleCommentary:
            return IconCrossFilled;
        case PublicationType.Book:
            return IconBook2;
        case PublicationType.Devotional:
            return IconCalendar;
        case PublicationType.Dictionary:
            return IconBook;
        case PublicationType.Manuscript:
            return IconWriting;
        case PublicationType.PeriodicalNoPageBreak:
        case PublicationType.PeriodicalPageBreak:
            return IconNews;
        case PublicationType.ScriptureIndex:
        case PublicationType.TopicalIndex:
            return IconPageBreak
        default:
            return IconQuestionMark;
    }
}

interface PublicationIconProps extends TablerIconsProps {
    type: PublicationType

}

export const PublicationIcon = ({type, ...rest}: PublicationIconProps) => {
    const Icon = getPublicationIcon(type);
    return <Icon {...rest}/>;
}