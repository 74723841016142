import {Box, Code, Group, LoadingOverlay, rem, Text, Title, useMantineTheme} from "@mantine/core";
import {Dropzone, FileWithPath} from "@mantine/dropzone";
import {IconArchive, IconUpload, IconX} from "@tabler/icons-react";
import React, {useState} from "react";
import {useAuth} from "react-oidc-context";
import {useDocumentTitle} from "@mantine/hooks";
import {API_URI} from "../../_config";
import {notifications} from "@mantine/notifications";
import {modals} from "@mantine/modals";

const allowedMimeTypes = [
    "text/html"
]

const validateFile = async (file: FileWithPath, accessToken: string): Promise<string | null> => {
    const uri = API_URI + '/import/validate';
    const data = new FormData();
    data.append('file', file, file.name);
    const response = await fetch(uri, {
        method: 'POST',
        body: data,
        headers: {
            'authorization': `Bearer ${accessToken}`
        }
    });
    if (response.status > 400) {
        return JSON.stringify(await response.json(), null, 2);
    }
    return null;
}
export const ValidatePage = () => {
    const auth = useAuth();
    useDocumentTitle("Imports");
    const theme = useMantineTheme();
    const [uploading, setUploading] = useState<boolean>(false);
    const handleDrop = async (newFiles: FileWithPath[]) => {
        if (newFiles.length !== 1) {
            return;
        }
        setUploading(true);
        try {
            const error = await validateFile(newFiles[0], auth.user?.access_token ?? '');
            if (error) {
                modals.open({
                    title: "Error",
                    centered: true,
                    children: (
                        <Code block>{error}</Code>
                    ),
                    color: 'red',
                    size: 'xl',

                })
            } else {
                notifications.show({
                    title: 'Success',
                    color: 'green',
                    message: 'Validation was successful'
                });
            }
        } finally {
            setUploading(false);
        }
    }
    return <>
        <Group mb={'md'} position="apart">
            <Title order={3}>Validate</Title>
        </Group>
        <Box style={{position: 'relative'}}>
            <LoadingOverlay visible={uploading}/>
            <Dropzone onDrop={handleDrop} maxSize={200 * 1024 * 1024}
                      accept={allowedMimeTypes}>
                <Group position="center" spacing="xl" style={{minHeight: rem(220), pointerEvents: 'none'}}>
                    <Dropzone.Accept>
                        <IconUpload
                            size="3.2rem"
                            stroke={1.5}
                            color={theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 4 : 6]}
                        />
                    </Dropzone.Accept>
                    <Dropzone.Reject>
                        <IconX
                            size="3.2rem"
                            stroke={1.5}
                            color={theme.colors.red[theme.colorScheme === 'dark' ? 4 : 6]}
                        />
                    </Dropzone.Reject>
                    <Dropzone.Idle>
                        <IconArchive size="3.2rem" stroke={1.5}/>
                    </Dropzone.Idle>

                    <div>
                        <Text size="xl" inline>
                            Drag html file here
                        </Text>
                    </div>
                </Group>
            </Dropzone>
        </Box>
    </>
}