import {useAuth} from "react-oidc-context";
import React from "react";
import {Divider, Navbar, NavLink, ScrollArea} from "@mantine/core";
import {IconFileExport, IconFileImport, IconLogout, IconQuestionMark, IconRefresh} from "@tabler/icons-react";
import {Link} from 'react-router-dom';

export const AdminNavBar = () => {
    const auth = useAuth();

    if (!auth.isAuthenticated) {
        return null;
    }
    return <Navbar width={{base: 300}} p="xs">
        <Navbar.Section grow mt="xs" component={ScrollArea} mx={'-xs'} px={'xs'}>
            <NavLink icon={<IconFileExport/>} component={Link} to={'/admin/export'} label={'Export'}/>
            <NavLink icon={<IconFileImport/>} component={Link} to={'/admin/import'} label={"Import"}/>
            <NavLink icon={<IconQuestionMark/>} component={Link} to={'/admin/validate'} label={'Validate'}/>
            <Divider/>
            <NavLink icon={<IconRefresh/>} component={Link} to={'/admin/refresh'} label={'Recalculate sort orders'}/>
        </Navbar.Section>
        <Navbar.Section>

            <NavLink icon={<IconLogout/>} onClick={auth.removeUser}
                     label={`Log out ${auth.user?.profile.preferred_username ?? ''}`}/>

        </Navbar.Section>
    </Navbar>
}
