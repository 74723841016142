import React from "react";
import {useMantineTheme} from "@mantine/core";

export function WNonEgw({children}: { children: React.ReactNode[] }) {
    const theme = useMantineTheme();
    return <span style={{
        color: theme.primaryColor
    }}>
        {children}
    </span>
}