import React from "react";
import {ActionIcon, HoverCard, useMantineTheme} from "@mantine/core";

export function WNote({title, children}: { title: React.ReactNode, children: React.ReactNode[] }) {
    return <span style={{display: "inline-block"}}>
        <HoverCard width={300} shadow={'md'}>
            <HoverCard.Target>
                <ActionIcon variant={'outline'} size={'md'} mx={'xs'}>
                    <div>{title}</div>
                </ActionIcon>
            </HoverCard.Target>
            <HoverCard.Dropdown>
                {children}
            </HoverCard.Dropdown>
        </HoverCard>
    </span>
}