import React from "react";
import {Box, Sx} from "@mantine/core";

function getStyle(indent?: string,
                  role?: string,
                  align?: string): Sx {

    let textAlign: "right" | "center" | undefined = undefined
    if (align === "right") {
        textAlign = "right"
    } else if (align === "center") {
        textAlign = "center"
    }
    let indentValue = parseInt(indent ?? "");
    if (Number.isNaN(indentValue)) {
        indentValue = 1

    }

    return {
        ":root": {
            textAlign: textAlign,
            textIndent: indentValue > 0 ? (indentValue + "em") : undefined
        }
    }
}

export function WPara({indent, role, align, children}: {
    indent?: string,
    role?: string,
    align?: string,
    children: React.ReactNode[]
}) {
    return <Box sx={getStyle(indent, role, align)}>{children}</Box>
}