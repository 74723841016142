import React from "react";
import {Card, Loader, LoadingOverlay, Title} from "@mantine/core";

export function LoadingCard({model, title, loading, children}: {
    model: unknown,
    title: React.ReactElement,
    loading: boolean,
    children: React.ReactNode
}) {
    return <Card>
        {model
            ?
            <>
                <LoadingOverlay visible={loading} />
                <Title order={3}>{title}</Title>
                {children}
            </>
            : <Loader/>
        }
    </Card>
}