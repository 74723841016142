import {GenericLayout} from "../GenericLayout";
import React from "react";
import {AdminNavBar} from "./AdminNavBar";

export const AdminLayout = ({children}: { children?: React.ReactNode | React.ReactNode[] }) => {
    return <GenericLayout
        navbar={<AdminNavBar/>}
    >
        {children}
    </GenericLayout>
}
