import {BookDetailsForReading__ChapterFragment} from "../../_gql/graphql";
import {DebugPanel} from "../../components/DebugPanel";
import {Collapse, Group, MantineTheme, Navbar, Text} from "@mantine/core";
import {buildChapterTree, ITreeItem} from "../../shared/buildChapterTree";
import {useState} from "react";
import {TreeExpandButton} from "../../components/TreeExpandButton";
import {Link} from "react-router-dom";


const treeChapterItemSx = (t: MantineTheme) => ({
    "&:hover": {
        backgroundColor: t.colors.gray[1]
    }
})
const TreeChapterItem = ({chapter, expanded}: {
    expanded?: boolean,
    chapter: ITreeItem<BookDetailsForReading__ChapterFragment, unknown>
}) => {
    const [isExpanded, setExpanded] = useState(expanded ?? false)
    const hasChildren = chapter.children && chapter.children.length > 0;
    return <>
        <Group position={'apart'} noWrap sx={treeChapterItemSx}>
            <Text size={'xs'} lineClamp={3} pt={'md'} pl={'sm'} component={Link} to={`/read/${chapter.paraId}`}>
                {chapter.title}
            </Text>
            {hasChildren && <TreeExpandButton expanded={isExpanded} onExpand={setExpanded} size={'xs'}/>}
        </Group>
        {hasChildren && <Collapse in={isExpanded} ml={'xs'}>
            {chapter.children?.map(child => <TreeChapterItem
                key={child.paraId}
                chapter={child}/>)}
        </Collapse>}
    </>

}

export function ReadPageNavbar({chapters}: { chapters?: readonly BookDetailsForReading__ChapterFragment[] }) {
    if (!chapters) {
        return null;
    }
    const treeChapters = buildChapterTree(chapters, []);
    return <Navbar width={{base: 300}} p="xs">
        <Navbar.Section grow>
            {treeChapters.map((chapter) =>
                <TreeChapterItem
                    expanded={true}
                    chapter={chapter} key={chapter.paraId}/>)}
            {/*<DebugPanel value={chapters}/>*/}
        </Navbar.Section>
    </Navbar>
}