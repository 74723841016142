import {
    PublicationViewPage_AuthorFragmentDoc,
    PublicationViewPage_LanguageFragmentDoc,
    PublicationViewPage_OriginalPublicationFragmentDoc
} from "../../../_gql/graphql";
import {useFragment} from "../../../_gql";
import {Badge, Divider, Group, Stack, Text, Title} from "@mantine/core";
import {Link} from "react-router-dom";
import React from "react";
import {ITabComponent} from "./PublicationViewPageInternal";

export const PublicationInfo = ({model}: ITabComponent) => {

    const language = useFragment(PublicationViewPage_LanguageFragmentDoc, model.language);
    const original = useFragment(PublicationViewPage_OriginalPublicationFragmentDoc, model.originalPublication);
    const author = useFragment(PublicationViewPage_AuthorFragmentDoc, model.author);
    const remainder = {...model} as any
    delete remainder.language;
    delete remainder.originalPublication;
    delete remainder.code;
    delete remainder.type;
    delete remainder.permission;
    delete remainder.title;
    delete remainder.author;
    delete remainder.description;

    return <Stack>
        <Group my={'md'} noWrap align={'baseline'}>
            <Stack>
                <Title order={4}>{model.code}</Title>
                <Badge>{model.type}</Badge>
                <Badge>{model.permission}</Badge>
                <Text size={'md'}>{language?.title}</Text>
                {original &&
                    <Text size={'xs'}><Link to={'/tree/pub/' + original.publicationId}> {original.title}</Link></Text>}
            </Stack>
            <Divider orientation={'vertical'}/>
            <Stack>
                <Title order={4}>{model.title}</Title>
                {author && <Text>by {author.firstName} {author.middleName} {author.lastName}</Text>}
                <Text color={"dimmed"} size={'sm'}>{model.description}</Text>

            </Stack>
        </Group>
    </Stack>
}
