import {Outlet, Routes} from "react-router-dom";
import {Route} from "react-router";
import {IndexPage} from "./IndexPage";
import React from "react";
import {NotFoundPage} from "../error-pages/NotFoundPage";
import {ContentLayout} from "../layout/content/ContentLayout";
import {AdminLayout} from "../layout/admin/AdminLayout";
import {ExportPage} from "./admin/ExportPage";
import {ImportPage} from "./admin/ImportPage";
import {ValidatePage} from "./admin/ValidatePage";
import {RecalculatePage} from "./admin/RecalculatePage";
import {AddPublicationPage} from "./tree/AddPublicationPage";
import {BrowseLanguagePage} from "./tree/BrowseLanguagePage";
import {PublicationViewPage} from "./tree/PublicationViewPage";
import {ReadPage} from "./ReadPage";


export const ApplicationRoutes = () => {
    return <Routes>
        <Route path='/' element={<ContentLayout><Outlet/></ContentLayout>}>
            <Route path="" element={<IndexPage/>}/>
        </Route>
        <Route path={'/read/:id'} element={<ReadPage />} />

        <Route path={"/admin"} element={<AdminLayout><Outlet/></AdminLayout>}>
            <Route path='export' element={<ExportPage/>}/>
            <Route path='import' element={<ImportPage/>}/>
            <Route path='validate' element={<ValidatePage/>}/>
            <Route path='refresh' element={<RecalculatePage/>}/>
        </Route>
        <Route path={"/tree"} element={<ContentLayout><Outlet/></ContentLayout>}>
            <Route path={'folder/:id'} element={<AddPublicationPage/>}/>
            <Route path={'lang/:id'} element={<BrowseLanguagePage/>}/>
            <Route path={'pub/:id'} element={<PublicationViewPage/>}/>
        </Route>
        <Route path={'*'} element={<NotFoundPage/>}/>
    </Routes>
}
