import {TreePublicationDtoFragment} from "../../../_gql/graphql";
import {MantineTheme, Menu, Text} from "@mantine/core";
import React, {useState} from "react";
import {PublicationMenu} from "./PublicationMenu";
import {PublicationIcon} from "../../../components/PublicationIcon";
import {Link} from "react-router-dom";
import {getPermissionColor} from "../../../shared/getPermissionColor";

const publicationStyles = (theme: MantineTheme) => ({
    padding: '2px',
    // root: {
    "&:hover": {
        backgroundColor: theme.colors.gray[2]
    }
    // }
})

export const PublicationMenuItem = ({publication}: { publication: TreePublicationDtoFragment }) => {
    const [opened, setOpened] = useState(false);
    const handleClose = () => {
        setOpened(false);
    }
    const handleContextMenu = (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        setOpened(true);
    }
    const color = getPermissionColor(publication.permission);
    const title = <Text lineClamp={3}
                        size={'xs'}
                        color={'dimmed'}
                        component={Link}
                        onContextMenu={handleContextMenu}
                        to={`/tree/pub/${publication.publicationId}`}
                        sx={publicationStyles}
    >
        <PublicationIcon type={publication.type}
                         size={16}
                         color={color}
                         style={{display: 'inline-block', marginRight: '3px', marginBottom: '-3px'}}/>
        <b>{publication.code}</b>
        &nbsp;
        {publication.title}
    </Text>
    return (<Menu opened={opened} onClose={handleClose} position={'bottom-start'}>
        <Menu.Target>{title}</Menu.Target>
        <PublicationMenu publication={publication}/>
    </Menu>)
}