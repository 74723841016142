import {AppHeader} from "./header/AppHeader";
import {AppShell, Container, Paper} from "@mantine/core";
import React, {useEffect, useState} from "react";
import {oidcConfig} from "../_config";
import {useAuth} from "react-oidc-context";

export const GenericLayout = ({navbar, children}: {
    navbar?: React.ReactElement,
    children?: React.ReactNode | React.ReactNode[]
}) => {
    const auth = useAuth();
    const [userName, setUserName] = useState<string | null>(null);

    useEffect(() => {
        const fetcher = async () => {
            if (!auth.isAuthenticated || !auth.user?.access_token) {
                setUserName(null);
                return;
            }
            setUserName(null);
            const userDataResponse = await fetch(oidcConfig.authority + "/connect/legacy-userinfo", {
                headers: {
                    Authorization: "Bearer " + (auth.user.access_token ?? '')
                }
            });
            const userData = await userDataResponse.json() as { id: number, username: string };
            setUserName(userData.username);
        }
        void fetcher();
    }, [auth.isAuthenticated, auth.user?.access_token])

    return <AppShell header={<AppHeader userName={userName ?? ""}/>}
                     navbar={navbar}
                     sx={theme => ({
                         minHeight: '100vh',
                         backgroundColor: theme.colors.gray[2]
                     })}
    >
        <Container>
            <Paper shadow="xl" p="md">
                {children}
            </Paper>
        </Container>
    </AppShell>
}