import React from 'react';
import './App.css';
import {useAuth} from 'react-oidc-context';
import {MantineProvider} from '@mantine/core';
import {API_URI} from "./_config";
import {createClient, Provider} from 'urql';
import {BrowserRouter} from "react-router-dom";
import {ApplicationRoutes} from "./pages/ApplicationRoutes";
import {NotAuthorizedPage} from "./error-pages/NotAuthorizedPage";
import {Notifications} from "@mantine/notifications";
import {ModalsProvider} from "@mantine/modals";

function App() {
    const auth = useAuth();
    const fetchOptions = () => {
        const token = auth.user?.access_token;
        return token ? {
            headers: {
                Authorization: `Bearer ${auth.user?.access_token}`
            }
        } : {};
    };
    const client = createClient({
        url: API_URI + '/graphql',
        fetchOptions
    })

    return <MantineProvider withGlobalStyles withNormalizeCSS>
        <Notifications/>
        <ModalsProvider>
            <BrowserRouter>
                <Provider value={client}>
                    {auth.isAuthenticated
                        ? <ApplicationRoutes/>
                        : <NotAuthorizedPage/>
                    }
                </Provider>
            </BrowserRouter>
        </ModalsProvider>
    </MantineProvider>
}

export default App;
