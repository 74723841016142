import {
    PublicationGetChaptersDocument,
    PublicationViewPage_ChapterFragment,
    PublicationViewPage_ChapterFragmentDoc,
    PublicationViewPage_Mp3Fragment,
    PublicationViewPage_Mp3FragmentDoc,
    PublicationViewPage_PublicationChapterDtoFragmentDoc
} from "../../../_gql/graphql";
import {useQuery} from "urql";
import {useFragment} from "../../../_gql";
import {useMemo} from "react";

export const useChapters = (id: number): {
    chapters: readonly PublicationViewPage_ChapterFragment[],
    mp3: readonly  PublicationViewPage_Mp3Fragment[],
    fetching: boolean
} => {
    const [{data, fetching}] = useQuery({
        query: PublicationGetChaptersDocument,
        variables: {id}
    });
    const publication = useFragment(PublicationViewPage_PublicationChapterDtoFragmentDoc,
        data?.publication
    );
    const items = useFragment(
        PublicationViewPage_ChapterFragmentDoc,
        publication?.chapters
    );
    const mp3Items = useFragment(
        PublicationViewPage_Mp3FragmentDoc,
        publication?.mp3Files
    );

    const chapters = useMemo(() => {
        return items ?? []
    }, [items]);
    const mp3 = useMemo(() => {
        return mp3Items ?? []
    }, [mp3Items]);
    return {
        chapters,
        mp3,
        fetching
    };
}