import {useParams} from "react-router-dom";
import {useQuery} from "urql";
import {
    AddPublicationPagePublicationDtoFragmentDoc,
    GetUnpublishedPublicationsForLanguageDocument
} from "../../_gql/graphql";
import {useFragment} from "../../_gql";
import {Divider, Title} from "@mantine/core";
import {PublicationTable} from "./components/PublicationTable";

const BrowseLanguagePageInner = ({languageCode}: { languageCode: string }) => {
    const [{data}] = useQuery({
        query: GetUnpublishedPublicationsForLanguageDocument,
        variables: {languageCode}
    });
    const publications = useFragment(AddPublicationPagePublicationDtoFragmentDoc, data?.publications?.items) ?? undefined;
    return <>
        <Title order={3} mb={'md'}>Browse publications for {languageCode}</Title>
        <Divider my={'md'}/>
        <PublicationTable
            publications={publications}
        />
    </>;
}
export const BrowseLanguagePage = () => {
    const {id} = useParams<{ id: string }>();
    if (!id) {
        return null;
    }
    return <BrowseLanguagePageInner languageCode={id}/>

}