import {AddPublicationPagePublicationDtoFragment,} from "../../../_gql/graphql";
import {Badge, Table} from "@mantine/core";
import React from "react";

type PublicationActions = (publication: AddPublicationPagePublicationDtoFragment) => React.ReactElement
const TableRow = ({
                      publication,
                      actions
                  }: {
    publication: AddPublicationPagePublicationDtoFragment,
    actions?: PublicationActions
}) => {
    const button = actions ? actions(publication) : null;
    return <tr key={publication.publicationId}>
        <th>{publication.publicationId}</th>
        <td>{publication.code}</td>
        <td>{publication.title}</td>
        <td><Badge>{publication.type}</Badge></td>
        <td>{button}</td>
    </tr>
}

export const PublicationTable = ({
                                     publications,
                                     actions,
                                 }: {
    publications?: readonly AddPublicationPagePublicationDtoFragment[],
    actions?: PublicationActions
}) => {
    return publications
        ? <Table>
            <thead>
            <tr>
                <th>Id</th>
                <th>Code</th>
                <th>Title</th>
                <th>Type</th>
                <th>&nbsp;</th>
            </tr>
            </thead>
            <tbody>
            {publications?.map(p => <TableRow
                key={p.publicationId}
                publication={p}
                actions={actions}/>)}
            </tbody>
        </Table>
        : null;
}