import {useQuery} from "urql";
import {graphql, useFragment} from "../_gql";
import {AuthorSelector_ListAuthorsDocument, AuthorSelector_PublicationAuthorFragmentDoc} from "../_gql/graphql";
import {Select, SelectItem} from "@mantine/core";
import {useMemo} from "react";

graphql(`
    fragment AuthorSelector_PublicationAuthor on PublicationAuthorDto {
        id
        code
        firstName
        middleName
        lastName
    }
    query AuthorSelector_ListAuthors {
        authors{
            items {
                ...AuthorSelector_PublicationAuthor
            }
        }
    }
`)

const useAuthors = () => {
    const [{data}] = useQuery({
        query: AuthorSelector_ListAuthorsDocument
    });
    const authors = useFragment(AuthorSelector_PublicationAuthorFragmentDoc, data?.authors?.items);
    return useMemo(() => (authors?.map(author => ({
        label: (author.code ? `[${author.code}] ` : '') + `${author.firstName} ${author.middleName} ${author.lastName}`,
        value: "" + author.id
    }) as SelectItem) ?? []), [authors]);
}
export const AuthorSelector = (props: { label: string }) => {
    const authors = useAuthors();
    return <Select data={authors}
                   searchable
                   clearable
                   {...props} />
}