import {useQuery} from "urql";
import {ChildFolderDtoFragmentDoc, GetChildFoldersDocument} from "../../../_gql/graphql";
import {useFragment} from "../../../_gql";

export function useGetChildFolders(folderId: number) {
    const [{data, fetching}, fetch] = useQuery({
        query: GetChildFoldersDocument,
        pause: true,
        variables: {
            parent: folderId
        }
    })

    const folders = useFragment(ChildFolderDtoFragmentDoc, data?.folders);
    return {
        folders,
        fetching,
        fetch
    }

}