import {ITabComponent} from "./PublicationViewPageInternal";
import {PublicationViewPage_ChapterFragment, PublicationViewPage_Mp3Fragment} from "../../../_gql/graphql";
import React, {useMemo, useState} from "react";
import {buildChapterTree, ITreeItem} from "../../../shared/buildChapterTree";
import {Badge, Box, Collapse, Flex, LoadingOverlay, MantineTheme, Text, ThemeIcon} from "@mantine/core";
import {Link} from "react-router-dom";
import {useChapters} from "./useChapters";
import {TreeExpandButton} from "../../../components/TreeExpandButton";
import {IconMusic} from "@tabler/icons-react";


const MenuItem = ({item, opened}: {
    item: ITreeItem<PublicationViewPage_ChapterFragment, PublicationViewPage_Mp3Fragment>,
    opened?: boolean
}) => {
    const [isOpened, setOpened] = useState(opened ?? false);
    const handleToggle = () => {
        setOpened(!isOpened);
    }
    const menuText = <Text size={'sm'} component={Link}
                           to={`/read/${item.paraId}`} style={{display: 'block', flexGrow: 1}}
                           lineClamp={2}
                           px={'xs'}
                           pt={'xs'}>
        {item.title}
    </Text>;
    const themeBuilder = (theme: MantineTheme) => ({
        '&:hover': {
            backgroundColor: theme.colors.gray[1]
        }
    });
    const hasChildren = item.children && item.children.length > 0;
    return <Box>
        <Flex sx={themeBuilder} align={'center'} mb={3}>
            {menuText}

            {item.mp3 && <ThemeIcon title={item.mp3.filename} size={'xs'} variant={'outline'}><IconMusic/></ThemeIcon>}
            {item.paragraph?.refCodeShort &&
                <Badge size={'sm'} mr={'xs'} sx={{flexShrink: 0}}>{item.paragraph.refCodeShort}</Badge>}
            {hasChildren
                ? <TreeExpandButton expanded={isOpened} onExpand={handleToggle} size={'sm'} mr={'xs'}/>
                : <Box mr={32}/>
            }
        </Flex>
        {hasChildren && <Collapse in={isOpened} ml={'xs'}>
            {isOpened && item.children!.map((child) => <MenuItem item={child} key={child.paraId}/>)}
        </Collapse>}
    </Box>
}

export const PublicationToc = ({
                                   model
                               }: ITabComponent) => {
    const {chapters, mp3, fetching} = useChapters(model.publicationId);
    const chapterTree = useMemo(() => {
        let result = buildChapterTree(chapters, mp3);
        if (result.length === 1) {
            result = result[0].children ?? [];
        }
        return result;
    }, [chapters, mp3])
    return <Box sx={{
        minHeight: 200,
    }}>
        <LoadingOverlay visible={fetching} title={'Loading chapters...'}/>
        {chapterTree.map(item => <MenuItem item={item} key={item.paraId}/>)}
    </Box>
}