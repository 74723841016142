import {PublicationPermissionEnum} from "../_gql/graphql";
import {MantineColor} from "@mantine/core";

export function getPermissionColor(permission: PublicationPermissionEnum): MantineColor | undefined {
    let color: MantineColor | undefined = undefined;

    switch (permission) {
        case PublicationPermissionEnum.Public:
            color = 'green';
            break;
        case PublicationPermissionEnum.Hidden:
            color = 'black';
            break;
        case PublicationPermissionEnum.AuthenticatedOnly:
            color = 'blue';
            break
        case PublicationPermissionEnum.PurchasedOnly:
            color = 'red';
            break
    }
    return color;
}
