type IChapter = {
    paraId: string
    level: number;
}
type ILinkedData = {
    paraId: string
}


export type ITreeItem<TChapter, TLinkedData> = TChapter & {
    mp3?: TLinkedData,
    children?: ITreeItem<TChapter, TLinkedData>[]
}

function getChildren<T extends IChapter, TData extends ILinkedData>(
    startPosition: number,
    chapters: readonly T[],
    mp3: readonly TData[]
): ITreeItem<T, TData>[] {
    const currentLevel = chapters[startPosition].level;
    const result: ITreeItem<T, TData>[] = [];
    for (let i = startPosition + 1; i < chapters.length; i++) {
        const chapter = chapters[i];
        if (chapter.level <= currentLevel) {
            break
        }
        if (chapter.level === currentLevel + 1) {
            const children = getChildren(i, chapters, mp3);
            result.push({
                ...chapter,
                mp3: mp3.find(r => r.paraId === chapter.paraId) ?? undefined,
                children: children && children.length > 0 ? children : undefined
            })
        }
    }
    return result;
}

export function buildChapterTree<T extends IChapter, TData extends ILinkedData>(
    chapters: readonly T[],
    mp3: readonly TData[]
): ITreeItem<T, TData>[] {
    const result: ITreeItem<T, TData>[] = [];
    for (let i = 0; i < chapters.length; i++) {
        const currentChapter = chapters[i];
        if (currentChapter.level !== 1) {
            continue;
        }
        const children = getChildren(i, chapters, mp3);
        result.push({
            ...currentChapter,
            mp3: mp3.find(r => r.paraId === currentChapter.paraId) ?? undefined,
            children: children && children.length > 0 ? children : undefined
        })

    }
    return result;
}
