import {PublicationViewPage_PublicationFragment} from "../../../_gql/graphql";
import {Tabs} from "@mantine/core";
import {IconEdit, IconFile, IconInfoCircle, IconJson, IconList, IconMusic} from "@tabler/icons-react";
import React from "react";
import {PublicationInfo} from "./PublicationInfo";
import {PublicationEditForm} from "./PublicationEditForm";
import {PublicationFiles} from "./PublicationFiles";
import {PublicationMp3Files} from "./PublicationMp3Files";
import {PublicationToc} from "./PublicationToc";

export interface ITabComponent {
    model: PublicationViewPage_PublicationFragment,
}

export const PublicationViewPageInternal = function ({model, tab, onChange}: {
    tab: string,
    onChange: (value: string) => void
} & ITabComponent) {
    function handleChangeTab(value: string) {
        onChange && onChange(value);
    }

    return <Tabs value={tab} onTabChange={handleChangeTab} keepMounted={false}>
        <Tabs.List>
            <Tabs.Tab value={'info'} icon={<IconInfoCircle/>}>Info</Tabs.Tab>
            <Tabs.Tab value={'json'} icon={<IconJson/>}>Json</Tabs.Tab>
            <Tabs.Tab value={'edit'} icon={<IconEdit/>}>Edit</Tabs.Tab>
            <Tabs.Tab value={'toc'} icon={<IconList/>}>Contents</Tabs.Tab>
            <Tabs.Tab value={'files'} icon={<IconFile/>}>Files</Tabs.Tab>
            <Tabs.Tab value={'mp3'} icon={<IconMusic/>}>MP3</Tabs.Tab>
        </Tabs.List>
        <Tabs.Panel value={'info'}>
            <PublicationInfo model={model}/>
        </Tabs.Panel>
        <Tabs.Panel value={'json'}>
            <code>
                <pre>{JSON.stringify(model, null, 2)}</pre>
            </code>
        </Tabs.Panel>
        <Tabs.Panel value={'edit'}>
            <PublicationEditForm model={model}/>
        </Tabs.Panel>
        <Tabs.Panel value={'toc'}>
            <div style={{position: "relative"}}>
                <PublicationToc model={model}/>
            </div>
        </Tabs.Panel>
        <Tabs.Panel value={'files'}>
            <PublicationFiles model={model}/>
        </Tabs.Panel>
        <Tabs.Panel value={'mp3'}>
            <PublicationMp3Files model={model}/>
        </Tabs.Panel>
    </Tabs>
}
