import {useAuth} from "react-oidc-context";
import React from "react";
import {Button, Group, Header, Menu, Text} from "@mantine/core";
import {IconKey, IconLogin, IconLogout, IconUser} from "@tabler/icons-react";
import {NavLink} from "react-router-dom";

const UserProfile = ({userName}: { userName: string }) => {
    const auth = useAuth();
    const handleLogOut = () => {
        void auth.removeUser();
    }
    return <Menu>
        <Menu.Target>
            <Button variant={"subtle"} leftIcon={<IconUser/>}>{userName}</Button>
        </Menu.Target>
        <Menu.Dropdown>

            <Menu.Divider/>
            <Menu.Item color={'red'} icon={<IconKey/>} component={NavLink} to={'/admin'}>Admin area</Menu.Item>
            <Menu.Divider/>
            <Menu.Item color={'red'} icon={<IconLogout/>} onClick={handleLogOut}>Log out</Menu.Item>
        </Menu.Dropdown>
    </Menu>
}

export const AppHeader = ({userName}: { userName: string }) => {
    const auth = useAuth();

    const handleLogIn = () => {
        void auth.signinRedirect();
    }
    return <Header height={{base: 50, md: 70}} p={'md'}>
        <Group position={'apart'}>
            <Text weight={'bold'} component={NavLink} to={'/'}>EGW Publication Management</Text>
            <Group spacing={'xs'}>
                {auth.isAuthenticated
                    ? <UserProfile userName={userName}/>
                    : <Button leftIcon={<IconLogin/>} onClick={handleLogIn}>Log in</Button>}
            </Group>
        </Group>
    </Header>
}