import {
    ChildFolderDtoFragment,
    LanguageDtoFragment,
    RootFolderDtoFragment,
    TreePublicationDtoFragmentDoc
} from "../../../_gql/graphql";
import React, {useMemo, useState} from "react";
import {useFragment} from "../../../_gql";
import {Center, Menu, NavLink, Progress, Text} from "@mantine/core";
import {PublicationMenuItem} from "./PublicationMenuItem";
import {ContextFolderMenu} from "./ContextFolderMenu";
import {ContextLanguageMenu} from "./ContextLanguageMenu";
import {useGetChildFolders} from "./useGetChildFolders";

type FolderMenuItemProps = {
    folder: ChildFolderDtoFragment
    language?: undefined
} | {
    folder: RootFolderDtoFragment,
    language: LanguageDtoFragment
}

function usePublications(props: FolderMenuItemProps) {
    const publications = useMemo(() => {
        if (props.language) {
            return [];
        }
        return (props.folder
                .publications
                ?.filter(r => r)
                .map(r => r!)
        ) ?? [];
    }, [props.folder, props.language]);
    return useFragment(
        TreePublicationDtoFragmentDoc,
        publications
    );
}

const LinkWithContextMenu = ({link, menu}: {
    link: React.ReactElement,
    menu?: React.ReactElement
}) => {
    const [menuOpened, setMenuOpened] = useState(false);
    if (menu == null) {
        return link;
    }
    const handleContextMenu = (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        setMenuOpened(true);
    }
    const handleMenuClose = () => {
        setMenuOpened(false);
    }
    return <Menu opened={menuOpened} onClose={handleMenuClose} position={'bottom-start'}>
        <Menu.Target>
            <div onContextMenu={handleContextMenu}>
                {link}
            </div>
        </Menu.Target>
        {menu}
    </Menu>
}
export const FolderMenuItem = (props: FolderMenuItemProps) => {
    const {folder, language} = props;
    const hasChildPublications = folder?.childPublicationCountRecursive > 0;
    const [opened, setOpened] = useState(false);
    const {folders, fetching, fetch: fetchChildFolders} = useGetChildFolders(folder.id)
    const publications = usePublications(props);
    const titleBlock = (
        <Text lineClamp={2} truncate
              color={hasChildPublications ? undefined : 'dimmed'}
        >{language?.title ?? folder.title}</Text>
    );
    const handleChangeOpened = () => {
        setOpened(!opened);
        if (!folders) {
            fetchChildFolders({
                pause: false
            })
        }
    }

    const hasChildren = folder?.childFolderCount > 0 || folder?.childPublicationCountRecursive > 0;

    const link = <NavLink label={titleBlock}
                          opened={opened}
                          onChange={handleChangeOpened}
                          p={0}
                          pb={4}
                          m={0}
                          color={hasChildPublications ? undefined : 'dimmed'}

    >
        {opened ?
            <>
                {publications.map(pub => <PublicationMenuItem key={pub.publicationId} publication={pub}/>)}
                {fetching
                    ? <Center><Progress size={'lg'} striped animate my={'md'}/></Center>
                    : folders?.map(child => <FolderMenuItem folder={child}
                                                            key={child.id}/>) ?? (hasChildren ? ' ' : null)}
            </>
            : <>xxx</>}
    </NavLink>
    const menu = useMemo(() => {
        return language ? <ContextLanguageMenu language={language}/> : <ContextFolderMenu folder={folder}/>;
    }, [language, folder])

    return <LinkWithContextMenu
        link={link}
        menu={menu}
    />
}
