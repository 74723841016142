import React from "react";
import {useMantineTheme} from "@mantine/core";

export function WSent({children}: { children: React.ReactNode[] }) {
    const theme = useMantineTheme();
    return <span style={{
        outline: "1px dotted " + theme.primaryColor
    }}>
        {children}
    </span>
}