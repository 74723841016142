import {CreateRootFolderForLanguageDocument, LanguageDtoFragment} from "../../../_gql/graphql";
import {useMutation} from "urql";
import {notifications} from "@mantine/notifications";
import {ActionIcon, Group, NavLink, Tooltip} from "@mantine/core";
import {IconFolderPlus} from "@tabler/icons-react";
import React, {useState} from "react";

export const EmptyLanguageMenuItem = ({language}: { language: LanguageDtoFragment }) => {
    const [, executeCreate] = useMutation(CreateRootFolderForLanguageDocument);
    const [x] = useState(true);
    const handleCreateRootLanguage = async () => {
        if (x) {
            return;
        }
        await executeCreate({
            languageCode: language.code
        });
        notifications.show({
            title: 'Root folder created',
            message: `Root folder created for ${language.title}`
        })
    }
    return <Group spacing={0} noWrap>
        <NavLink label={language.title}/>
        <Tooltip label={'Create root folder for language'}>
            <ActionIcon size={'xs'} onClick={handleCreateRootLanguage}><IconFolderPlus/></ActionIcon>
        </Tooltip>
    </Group>
}
