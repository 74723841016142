import {ITabComponent} from "./PublicationViewPageInternal";
import {useChapters} from "./useChapters";
import React from "react";
import {ScrollArea, Table, ThemeIcon, ThemeIconProps} from "@mantine/core";
import {VoiceTypeEnum} from "../../../_gql/graphql";
import {IconGenderFemale, IconGenderMale, IconRobot} from "@tabler/icons-react";
import {Link} from "react-router-dom";


const GenderIcon = ({gender}: { gender: VoiceTypeEnum }) => {
    return gender === VoiceTypeEnum.Male
        ? <IconGenderMale/>
        : <IconGenderFemale/>
}
type IsGeneratedIconProps = {
    isGenerated: boolean
} & Partial<ThemeIconProps>
const IsGeneratedIcon = ({isGenerated, ...rest}: IsGeneratedIconProps) => {
    return isGenerated ? <ThemeIcon {...rest}>
        <IconRobot/>
    </ThemeIcon> : null
}
export const PublicationMp3Files = ({model}: ITabComponent) => {
    const {mp3} = useChapters(model.publicationId);
    return <>
        <ScrollArea h={'100vh'}>
            <Table>
                <thead>
                <tr>
                    <th>Filename</th>
                    <th>File type</th>
                </tr>
                </thead>
                <tbody>
                {mp3.map((file) => <tr key={file.filename}>
                    <td>
                        <Link to={`/read/${file.paraId}`}>{file.filename}</Link>
                    </td>
                    <td>
                        <ThemeIcon size={'md'} variant={'outline'} mr={'xs'}>
                            <GenderIcon gender={file.voiceType}/>
                        </ThemeIcon>
                        <IsGeneratedIcon isGenerated={!file.isGenerated} size={'md'} variant={'outline'}/>
                    </td>
                </tr>)}
                </tbody>
            </Table>
        </ScrollArea>

    </>
}