import {AuthProviderProps} from "react-oidc-context";

interface CustomWindow extends Window {
    REACT_APP_BASE_URI?: string;
    REACT_APP_CLIENT_ID?: string;
    REACT_APP_WEBSITE_URI?: string
    REACT_APP_API_URI?: string;
}

declare let window: CustomWindow;

export const BASE_URI = window.REACT_APP_BASE_URI ?? process.env.REACT_APP_BASE_URI ?? "https://cpanel.egwwritings.org";
const CLIENT_ID = window.REACT_APP_CLIENT_ID ?? process.env.REACT_APP_CLIENT_ID ?? "";
const WEBSITE_URI = window.REACT_APP_WEBSITE_URI ?? process.env.REACT_APP_WEBSITE_URI ?? `${window.location.protocol}//${window.location.host}/`
export const API_URI = window.REACT_APP_API_URI ?? process.env.REACT_APP_API_URI ?? "https://localhost:7003"
export const oidcConfig: AuthProviderProps = {
    authority: BASE_URI,
    client_id: CLIENT_ID,
    redirect_uri: WEBSITE_URI,
    scope: "openid offline_access roles",
    post_logout_redirect_uri: '/',
    loadUserInfo: false,
    mergeClaims: true,
    filterProtocolClaims: false,
    onSigninCallback: async (user) => {
        if (!user) {
            return;
        }
        const userDataResponse = await fetch(oidcConfig.authority + "/connect/legacy-userinfo", {
            headers: {
                Authorization: "Bearer " + (user.access_token ?? '')
            }
        });
        const userData = await userDataResponse.json() as { id: number, username: string, is_superuser?: boolean };
        user.profile.preferred_username = userData.username;
        window.history.replaceState({}, document.title, window.location.pathname);
    }

};
