import {useQuery} from "urql";
import {
    LanguageDtoFragment,
    LanguageDtoFragmentDoc,
    ListLanguagesDocument,
    RootFolderDtoFragmentDoc
} from "../../../_gql/graphql";
import {useFragment} from "../../../_gql";
import React from "react";
import {EmptyLanguageMenuItem} from "./EmptyLanguageMenuItem";
import {FolderMenuItem} from "./FolderMenuItem";


const RootFolderComponent = ({language}: { language: LanguageDtoFragment }) => {
    const folder = useFragment(RootFolderDtoFragmentDoc, language.rootFolder)
    if (!folder) {
        return <EmptyLanguageMenuItem language={language} key={language.code}/>;
    }
    return <FolderMenuItem folder={{...folder}} key={language.code} language={language}/>
}
export const ContentLanguageTree = ({filter}: { filter: string }) => {
    const [{data: languageData}] = useQuery({query: ListLanguagesDocument});
    const languages = useFragment(LanguageDtoFragmentDoc, languageData?.languages) ?? [];
    return <>{languages.map(lang => <RootFolderComponent key={lang.code} language={lang}/>)}</>
}