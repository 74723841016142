import React from "react";
import {Title, TitleOrder} from "@mantine/core";

export function WHeading({level, children}: { level?: string, children?: React.ReactNode[] }) {
    let order = parseInt(level ?? "", 10) as TitleOrder;
    if (order < 1) {
        order = 1;
    }
    if (order > 6 || Number.isNaN(order)) {
        order = 6;
    }
    return <Title order={order}>
        {children}
    </Title>
}