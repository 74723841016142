import {IconChevronDown} from "@tabler/icons-react";
import {ActionIcon, MantineSize} from "@mantine/core";
import React from "react";
import {MantineStyleSystemProps} from "@mantine/styles/lib/theme/types/MantineStyleSystem";

type TreeExpandButtonProps = {
    expanded: boolean;
    onExpand: (newState: boolean) => void;
    size?: MantineSize
} & MantineStyleSystemProps;

const chevronStyle = (expanded: boolean) => ({
    transform: expanded ? undefined : 'rotate(-90deg)',
    transitionTimingFunction: 'ease',
    transition: '0.25s'
})

export function TreeExpandButton({expanded, onExpand, ...iconStyle}: TreeExpandButtonProps) {
    const handleToggle = () => {
        onExpand(!expanded);
    }
    return <ActionIcon onClick={handleToggle} {...iconStyle}>
        <IconChevronDown style={chevronStyle(expanded)}/>
    </ActionIcon>
}