import {useMutation, useQuery} from "urql";
import {
    AddPublicationPageFolderDtoFragmentDoc,
    AddPublicationPageFolderTypeDtoFragmentDoc,
    AddPublicationPagePublicationDtoFragmentDoc,
    GetPublicationPageDetailsDocument,
    GetUnpublishedPublicationsForFolderDocument, PublicationType,
    PublishPublicationDocument
} from "../../../_gql/graphql";
import {useFragment} from "../../../_gql";
import React, {useEffect} from "react";
import {NotFoundPage} from "../../../error-pages/NotFoundPage";
import {Badge, Button, Divider, Text, Title} from "@mantine/core";
import {PublicationTable} from "../components/PublicationTable";
import {IconPlus} from "@tabler/icons-react";

function useFolderDetails(folderId: number) {
    const [{data: folderDetails}] = useQuery({
        query: GetPublicationPageDetailsDocument,
        variables: {folderId}
    });
    const folder = useFragment(AddPublicationPageFolderDtoFragmentDoc, folderDetails?.folder);
    return {folder}
}

function usePublications(languageCode: string, types: PublicationType[]) {
    const [{data: publicationData}, refetchList] = useQuery({
        query: GetUnpublishedPublicationsForFolderDocument,
        variables: {
            languageCode,
            types
        },
        pause: true
    });
    useEffect(() => {
        refetchList({
            variables: {
                languageCode,
                types
            }
        })
    }, [refetchList, languageCode, types]);
    const publications = useFragment(AddPublicationPagePublicationDtoFragmentDoc, publicationData?.publications?.items);
    return {publications};
}

export function AddPublicationPageInternal({folderId}: { folderId: number }) {
    const [, executePublish] = useMutation(PublishPublicationDocument);

    const {folder} = useFolderDetails(folderId);
    const languageCode = folder?.language?.code;
    const type = useFragment(AddPublicationPageFolderTypeDtoFragmentDoc, folder?.type);
    const {publications} = usePublications(languageCode ?? '', type?.allowedTypes ?? []);
    const handlePublish = (publicationId: number) => {
        executePublish({
            folderId,
            publicationId
        }).then();
    }
    if (!folder) {
        return <NotFoundPage/>;
    }
    return <>
        <Title order={3} mb={'md'}>Adding publications to {folder?.title}@{languageCode}</Title>
        <Divider my={'md'}/>
        <Text>Allowed types: &nbsp;
            {type?.allowedTypes.map(t => <Badge key={t}>{t}</Badge>)}
        </Text>
        <Divider my={'md'}/>
        <PublicationTable
            publications={publications ?? undefined}
            actions={p => <Button onClick={() => handlePublish(p.publicationId)} size={'xs'}
                                  leftIcon={<IconPlus/>}>Add</Button>}
        />
    </>;
}
