import {
    ChangePublicationOrderDocument,
    ChangePublicationPermissionDocument,
    PublicationPermissionEnum,
    TreePublicationDtoFragment,
    UnpublishPublicationDocument
} from "../../../_gql/graphql";
import {useMutation} from "urql";
import {Menu} from "@mantine/core";
import {
    IconArrowDown,
    IconArrowsDown,
    IconArrowsUp,
    IconArrowUp,
    IconEdit,
    IconEye,
    IconTrash
} from "@tabler/icons-react";
import React from "react";
import {Link} from "react-router-dom";
import {getPermissionColor} from "../../../shared/getPermissionColor";

const StatusMenuItem = ({id, status, title}: { id: number, status: PublicationPermissionEnum, title: string }) => {
    const [, update] = useMutation(ChangePublicationPermissionDocument);
    const handleChangePermission = () => {
        update({
            publicationId: id,
            permission: status
        }).then(() => {

        });
    }
    return <Menu.Item onClick={handleChangePermission} color={getPermissionColor(status)}
                      icon={<IconEye size={14}/>}>{title}</Menu.Item>
}

export const PublicationMenu = ({publication}: { publication: TreePublicationDtoFragment }) => {
    const [, executeReorder] = useMutation(ChangePublicationOrderDocument);
    const [, executeUnpublish] = useMutation(UnpublishPublicationDocument);
    const setOrder = (newOrder: number) => {
        executeReorder({
            publicationId: publication.publicationId,
            order: newOrder
        }).then();
    }
    const handleUp = () => {
        setOrder(publication.order - 1.1)
    }
    const handleDown = () => {
        setOrder(publication.order + 1.1)
    }
    const handleStart = () => {
        setOrder(-1)
    }
    const handleEnd = () => {
        setOrder(100000)
    }
    const handleUnpublish = () => {
        executeUnpublish({
            publicationId: publication.publicationId
        })
    }

    return <Menu.Dropdown>
        <Menu.Label>{publication.title}</Menu.Label>
        <Menu.Item icon={<IconEdit size={14}/>} component={Link}
                   to={'/tree/pub/' + publication.publicationId}>Edit</Menu.Item>
        <Menu.Divider/>
        <StatusMenuItem id={publication.publicationId} status={PublicationPermissionEnum.Public}
                        title="Set public"/>
        <StatusMenuItem id={publication.publicationId} status={PublicationPermissionEnum.AuthenticatedOnly}
                        title="Set authenticated"/>
        <StatusMenuItem id={publication.publicationId} status={PublicationPermissionEnum.PurchasedOnly}
                        title="Set purchased"/>
        <StatusMenuItem id={publication.publicationId} status={PublicationPermissionEnum.Hidden}
                        title="Set hidden"/>
        <Menu.Divider/>
        <Menu.Item icon={<IconArrowsUp size={14}/>} onClick={handleStart}>Move to beginning</Menu.Item>
        <Menu.Item icon={<IconArrowUp size={14}/>} onClick={handleUp}>Move up</Menu.Item>
        <Menu.Item icon={<IconArrowDown size={14}/>} onClick={handleDown}>Move down</Menu.Item>
        <Menu.Item icon={<IconArrowsDown size={14}/>} onClick={handleEnd}>Move to end</Menu.Item>
        <Menu.Divider/>
        <Menu.Item icon={<IconTrash size={14}/>} color={'red'} onClick={handleUnpublish}>Unpublish</Menu.Item>
    </Menu.Dropdown>
}
