import {useParams} from "react-router-dom";
import React, {useMemo} from "react";
import {AddPublicationPageInternal} from "./AddPublicationPageInternal";


export function AddPublicationPage() {
    const {id} = useParams<{ id: string }>();
    const folderId = useMemo(() => Number.parseInt(id ?? '', 10), [id]);
    if (Number.isNaN(folderId) || folderId === 0) {
        return <b>NaN</b>;
    }
    return <AddPublicationPageInternal folderId={folderId}/>;
}